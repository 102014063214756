import React from 'react'
import Layout from '../../components/layout'
import { Link, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Submit = (props) => (

  <Layout>
    <BackgroundImage className='BackgroundImage' fluid={props.data.BackgroundImage.childImageSharp.fluid}>
      <div className='container text-center Submit-title'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 '>
          <p>Tack!</p>
          <p>Vi hör av oss till dig så fort vi kan</p>
          <Link to='/' className='btn btn-danger'> Tillbaka </Link>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export default Submit

export const pageQuery = graphql`
  query {
    BackgroundImage: file(relativePath: { eq: "ovrigt/golf.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`
